@font-face {
  font-family: "lg_webfont";
  src: url("../src/fonts/Prompt/light_webfont.woff");
}

@font-face {
  font-family: "md_webfont";
  src: url("../src/fonts/Prompt/medium-webfont.woff");
}

@font-face {
  font-family: "new_webfont";
  src: url("../src/fonts/Prompt/Prompt-SemiBold.ttf");
}

body {
  font-weight: 500;
  font-family: "lg_webfont" !important;
  /* font-family: "'Montserrat', sans-serif"; */
}

.swal2-styled.swal2-confirm {
  font-family: "lg_webfont" !important;
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: rgb(48, 133, 214) !important;
  /* background-color: rgb(138, 216, 121) !important; */
  /* color: #000; */
  font-size: 1.0625em;
}

.swal2-styled.swal2-cancel {
  font-family: "lg_webfont" !important;
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: rgb(243, 83, 58) !important;
  /* color: #000; */
  font-size: 1.0625em;
}

.swal2-container {
  z-index: 9999 !important;
}

/* .swal2-popup {
  font-family: "lg_webfont" !important;
  font-size: 0.9rem !important;
  width: 26rem !important;
} */

.swal2-input {
  font-family: "lg_webfont" !important;
}

::-webkit-scrollbar {
  height: 6px; /* height of horizontal scrollbar ← You're missing this */
  width: 6px; /* width of vertical scrollbar */
}

::-webkit-scrollbar-track {
  background: rgb(38, 38, 38);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(99, 99, 99);
  border-radius: 10px;
}
